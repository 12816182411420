// Global Styles

@import 'style-vars'; // @import '~src/style-vars';

// Reset ---

* {
    margin: 0; padding: 0; border: none;
    outline: 0; // chrome fix
    -webkit-overflow-scrolling: touch; // iOS fix
}
img {display: block;} // chrome fix
input, textarea, button, select, a { -webkit-tap-highlight-color: $transparent; }

// Styles Scrollbars
// https://css-tricks.com/custom-scrollbars-in-webkit/
::-webkit-scrollbar { width: 4px; height: 4px; }
::-webkit-scrollbar-track { background: $colorBlack;}
::-webkit-scrollbar-thumb { background: $colorPrimary;}
::-webkit-scrollbar-thumb:window-inactive { background: $colorPrimary;}

// Typography ---


h1, h2, h3, h4, h5, h6 {
    font-family: $fontFamily; letter-spacing: 1px;
}
    h1 {font-size: 48px; line-height: 56px;}
    h2 {font-size: 32px; line-height: 48px;}
    h3 {font-size: 28px; line-height: 32px;}
    h4 {font-size: 24px; line-height: 28px;}
    h5 {font-size: 18px; line-height: 24px;}
    h6 {font-size: 12px; line-height: 18px;}
    @media #{$smallBreakpoint} {
        h2 {font-size: 48px; line-height: 56px;}
    }
p {
    font-size: 16px; line-height: 24px;
    word-wrap: break-word;
    &+& {margin-top: 20px;}
}
a, button {cursor: pointer;}
pre {
    text-align: left;
    background: $colorBlack; color: white; padding: 16px; border-radius: $borderRadius;
    overflow-x: auto;
    &+& {margin-top: 20px;}
}
i {font-size: 24px; line-height: 24px;}

// Global ---

html, body {width: 100%; height: 100%;}
html {font-family: $fontFamily;}
body {background: $colorSurface; overflow: hidden;}

ul, ol, nav {
    li {list-style: none;}
    li+li {margin-top: 20px;}
}
hr {margin: 20px 0; border-top: 1px dotted $colorRaised;}
video {background: #141414;}

// Progress Bar
progress, progress::-webkit-progress-bar {
    -webkit-appearance: none; -moz-appearance: none; appearance: none; border: 0; // reset
    background: $colorBlack;
}
progress::-webkit-progress-value { background: $colorPrimary; transition: width 1s linear; }
progress::-moz-progress-bar { background: $colorPrimary; transition: width 1s linear; }
custom-progress::-ms-fill { background: $colorPrimary; transition: width 1s linear; }

// Forms
form {width: 100%;}
fieldset+fieldset {margin-top: 32px;}
label {
    display: block;
    span {
        display: block; margin-bottom: 4px;
        color: $colorWhite; color: $colorWhite;
        font-size: 12px; line-height: 18px;
    }
    &+& {margin-top: 10px;}
    .hint { margin-top: 4px; font-size: 12px; line-height: 18px; opacity: 0.66;}
}

// Placeholder Text
::-webkit-input-placeholder { color: $transparentWhite66; }
::-moz-placeholder { color: $transparentWhite66; }
:-ms-input-placeholder { color: $transparentWhite66; }
:-moz-placeholder { color: $transparentWhite66; }

// Input/Textarea
input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="date"],
textarea,
select {
    // Fill
    background: $transparentWhite10;
    border-bottom: 1px solid $transparentWhite33;
    box-sizing: border-box; width: 100%; padding: 16px;
    border-top-left-radius: $borderRadius/2; border-top-right-radius: $borderRadius/2;
    // Text
    color: $colorWhite;
    font-family: $fontFamily;
    font-size: 16px; line-height: 18px;
    // Transition
    transition: all 100ms linear;
    // Interaction
    &:hover { border-color: $colorWhite; }
    &:active, &:focus {
        border-color: $colorAccent;
    }
    // Utility
    &:disabled {opacity: 0.5; cursor: not-allowed; border: none;}
    &.ng-invalid.ng-touched {
        background-color: $colorPrimary; color: $colorWhite;
        border-color: $colorBlack;
    }
}
input[type="checkbox"] {margin-right: 10px;}

select {
    -moz-appearance: none; -webkit-appearance: none; appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>");
    background-repeat: no-repeat;
    background-position: right .7em top 50%;
    background-size: .65em auto;
    // Browser Specific
    &::-ms-expand { display: none; }
}

// Buttons
button, .button {
    display: inline-flex; justify-content: center; align-items: center;
    cursor: pointer;
    // Fill
    background: $colorWhite;
    box-sizing: border-box; border-radius: $borderRadius; box-shadow: 0 0 0 $transparentBlack10;
    padding: 12px 32px;
    // Text
    color: $colorBlack;
    font-family: $fontFamily;
    font-size: 18px; line-height: 20px; font-weight: bold;
    text-align: center; text-decoration: none; text-transform: uppercase;
    // Transitions
    transition: all 100ms linear;
    // Interaction
    &:hover {
        box-shadow: 0 3px 20px transparentBlack33;
        filter: brightness(1.05);
    }
    // Variations
    &.bgPrimary {color: $colorWhite;}
    &.stroked {
        background: transparent; border: 1px solid $transparentWhite33; color: $colorWhite;
        box-shadow: none;
        &:hover {border-color: $colorWhite;}
    }
    &.fabMini {
        width: 48px; height: 48px; border-radius: 24px; padding: 0;
        i, span {font-size: 20px; line-height: 50px;}
        span {font-family: $fontFamily; font-weight: bold;}
    }
    &.fab {
        width: 90px; height: 90px; border-radius: 100%; padding: 0;
        display: inline-flex; justify-content: center; align-items: center;
        i, span {font-size: 32px; line-height: 32px;}
        span {font-family: $fontFamily; font-weight: bold;}
    }
    // Utility
    &:disabled {
        background: $colorBlack; border: none; color: $transparentWhite33;
        box-shadow: none; filter: none;
        cursor: not-allowed;}
    // Responsive
    @media #{$xSmallBreakpoint} {
        padding: 8px 32px;
        font-size: 14px; line-height: 24px;
    }
}

// Button Sets
.btnSetVert {
    display: flex; flex-direction: column;
    * {flex: 0 0 auto;}
    *+* {margin: 16px 0 0 0;}
    button, .button {max-width: 300px;}
}
.btnSetHorz {
    display: flex; flex-direction: row;
    * {flex: 0 0 auto;}
    *+* {margin: 0 0 0 8px;}
}

// Dialogs
#dialogListPrizes {
    background: $colorSurface; border-radius: 8px; margin-top: 16px; padding: 16px;
    max-height: 300px; overflow-y: auto;
    li {
        padding: 8px 16px;
        display: flex; align-items: center;
        .rank {flex: 0 0 40px; opacity: 0.66;}
        .name {flex: 1.5; font-size: 20px; line-height: 24px; margin-left: 16px;}
        .desc {flex: 2; opacity: 0.66; margin-left: 16px;}
    }
    li+li {border-top: 1px solid $transparentWhite10; margin-top: 4px;}
}

// Classes ---

// Text
.textCenter {text-align: center;}
.textLeft {text-align: left;}
.textRight {text-align: right;}
.empMed {opacity: 0.66;}
.lowercase {text-transform: lowercase;}
.ellipsis { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.textHighlight {
    background: $colorSurface; padding: 4px 6px;
    &.active {background: $colorPrimary !important;}
}

// Icons
.iconSmall {font-size: 16px; line-height: 16px;}
.iconHuge {font-size: 100px; line-height: 100px;}

// Responsive Display
.displayMobile {
    display: none !important;
    @media #{$smallBreakpoint} { display: initial !important; }
}
.displayDesktop {
    display: initial !important;
    @media #{$smallBreakpoint} { display: none !important; }
}

// Element
.w100 {width: 100%;}
.marginAuto {margin: auto;}
.marginLeft8 {margin-left: 8px;}
.marginRight8 {margin-right: 8px;}
.marginLeft16 {margin-left: 16px;}
.marginRight16 {margin-right: 16px;}
.marginNone {margin: 0;}
.marginNegHorz {margin: 0 -16px;}
.floatLeft {float: left;}
.floatRight {float: right;}

// Text Colors
.colorPrimary {color: $colorPrimary;}
.colorAccent {color: $colorAccent;}
.colorWhite {color: $colorWhite;}
.colorBlack {color: $colorBlack;}
.colorGrey {color: $colorRaised;}

// Background Colors
.bgPrimary {background: $colorPrimary;}
.bgAccent {background: $colorAccent; color: $colorBlack !important;}
.bgNeutral {background: $colorWarn;}
.bgSurface {background: $colorSurface;}
.bgWhite {background: $colorWhite; color: $colorBlack; i {color: $colorBlack;}}
.bgBlack {background: $colorBlack; color: $colorWhite; i {color: $colorWhite;}}

// Icons
.iconSmall {font-size: 16px; line-height: 16px;}
.iconHuge {font-size: 100px; line-height: 100px;}

// Error
.error {
    background: $colorAccent; color: $colorBlack; padding: 8px 16px;
    font-size: 12px; line-height: 18px; text-align: center;
    border-radius: $borderRadius;
}

// Layout
.layoutCenter {
    display: flex; justify-content: center; align-items: center;
    height: 100%; width: 100%;
}

// Flexbox Grid
.flexGrid {
    display: flex; flex-direction: row;
    // Columns
    .col {flex: 1;}
    .col+.col {margin: 0 0 0 16px;}
    .flexWider {flex: 1.5;}
    .flexAuto {flex: 0 0 auto !important;}
    // Responsive
    @media #{$smallBreakpoint} {
        flex-direction: column;
        .col+.col {margin: 16px 0 0 0;}
    }
}
.flexAlignCenter {align-items: center;}
.flexJustifyCenter {justify-content: center;}
.flexJustifySpaceBetween {align-items: center;}
.flexNonResponsive {
    flex-direction: row !important;
    .col+.col {margin: 0 0 0 16px !important;}
}

// Avatars
.avatar {
    border-radius: 100%;
    &.large {
        width: 200px; height: 200px;
        @media #{$xSmallBreakpoint} { width: 150px; height: 150px; }
    }
    &.medium {width: 100px; height: 100px;}
    &.small {width: 50px; height: 50px;}
}

// Tabs
.tabs {
    background: $colorRaised;
    display: flex; margin-bottom: 16px;
    li {
        flex: 1;
        color: $transparentWhite66;
        border-bottom: 3px solid transparent; padding: 16px 0; text-align: center;
        cursor: pointer;
        i {display: block; margin: 0 auto 4px;}
        &:hover, &:active, &:focus {background: $transparentWhite10;}
    }
    li+li {margin: 0;}
    .active {color: $colorWhite; border-bottom: 3px solid $colorPrimary;}
}

// Badge Wrap
.badgeWrap {
    position: relative;
    .badge {
        background: $colorBlack; width: 10px; height: 10px; padding: 5px; border-radius: 100%;
        position: absolute; bottom: -3px; right: -3px; z-index: 1;
        display: flex; justify-content: center; align-items: center;
        i {font-size: 10px; line-height: 10px;}
    }
}

// Card
.card {
    background: $colorRaised; box-sizing: border-box;
    border-radius: $borderRadius; box-shadow: $boxShadow;
    padding: 16px; overflow: hidden;
}

// Progress Bar
progress::-webkit-progress-value { background: $colorWhite; }
progress::-moz-progress-bar { background: $colorWhite; }
custom-progress::-ms-fill { background: $colorWhite; }

// Animations ---

// Spin
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}
.animSpin { animation: spin 1s linear infinite; }

// Anmiated Background
// https://www.transparenttextures.com/
// animation: animatedBackground 5s linear infinite;
@keyframes animatedBackground {
	from { background-position: 0 0; }
	to { background-position: 35px -35px; }
}
.bgAnim { animation: animatedBackground 4s linear infinite; }

// Fade
@keyframes animFadeIn {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}
.animFadeIn { animation: animFadeIn 200ms linear; will-change: opacity; }
@keyframes animFadeOut {
    0%   { opacity: 1; }
    100% { opacity: 0; }
}
.animFadeOut { animation: animFadeOut 200ms linear; will-change: opacity; }

// Zoom
@keyframes animZoomIn {
    0%   { transform: scale(0); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
}
.animZoomIn { animation: animZoomIn 200ms linear; will-change: transform, opacity; }
@keyframes animZoomOut {
    0%   { transform: scale(1); opacity: 1; }
    100% { transform: scale(0); opacity: 0; }
}
.animZoomOut { animation: animZoomOut 200ms linear; will-change: transform; }

// Slide
@keyframes animSlideUp {
    0%   { transform: translate3d(0, 100%, 0); }
    100% { transform: translate3d(0, 0, 0); }
}
.animSlideUp { animation: animSlideUp 200ms linear; will-change: transform; }
@keyframes animSlideDown {
    0%   { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(0, 100%, 0); }
}
.animSlideDown { animation: animSlideDown 200ms linear; will-change: transform; }

// Snackbar
@keyframes animSnackbarUp {
    0%   { transform: translate3d(0, 100%, 0); }
    100% { transform: translate3d(0, 0, 0); }
}
.animSnackbarUp { animation: animSnackbarUp 50ms linear; will-change: transform; }
@keyframes animSnackbarDown {
    0%   { transform: translate3d(0, 0, 0); }
    100% { transform: translate3d(0, 100%, 0); }
}
.animSnackbarDown { animation: animSnackbarDown 50ms linear; will-change: transform; }

// 3D
@keyframes animHover {
    0%   { transform: scale3d(1,1,1); }
    50%  { transform: scale3d(1.05,1.05,1); }
    100% { transform: scale3d(1,1,1); }
}
.animHover {
    animation: animHover 1.5s ease infinite; will-change: transform;
    &:hover, &:active, &:focus { animation-play-state: paused; }
}
