// Variables ---

// Fonts
$fontFamily: "Roboto", "Helvetica Neue", sans-serif;

// Color Variables
$colorWhite: #FFFDE7;
$colorBlack: #212121;
$colorSurface: #263238;
$colorRaised: #37474F;
$colorPrimary: #F50057;
$colorAccent: #1DE9B6;
$colorWarn: #1A237E;

// Colors Transparent
$transparent: rgba(0,0,0,0);
$transparentBlack10: rgba(0,0,0,0.1);
$transparentBlack33: rgba(0,0,0,0.33);
$transparentBlack66: rgba(0,0,0,0.66);
$transparentWhite10: rgba(255,255,255,0.1);
$transparentWhite33: rgba(255,255,255,0.33);
$transparentWhite66: rgba(255,255,255,0.66);

// Border Radius
$borderRadius: 8px;

// Box Shadow
$boxShadow: 0 0 20px rgba(0,0,0,0.1);

// Mixins ---

// Ranges
@function lower-bound($range) {
    @if length($range) <= 0 { @return 0; }
    @return nth($range, 1);
}
@function upper-bound($range) {
    @if length($range) < 2 { @return 999999999999; }
    @return nth($range, 2);
}

// Breakpoints ---

$xxlarge-range: (1921px); // 4k
$xlarge-range:  (1441px, 1920px); // desktop
$large-range:   (1025px, 1440px); // tablet -> desktop
$medium-range:  (641px, 1024px); // tablet vert
$small-range:   (0px, 640px); // mobile
$xsmall-range:   320px; // iPhone 5

// Orientation
// div {
//     background: black;
//     @media #{$landscape} { background: red; }
//     @media #{$portrait} { background: green; }
// }
$landscape: "(orientation: landscape)";
$portrait:  "(orientation: portrait)";

// Exact
// div {
//     background: black;
//     @media #{$xxlargeBreakpoint} { background: red; }
//     @media #{$xlargeBreakpoint} { background: green; }
//     @media #{$largeBreakpoint} { background: blue; }
//     @media #{$mediumBreakpoint} { background: violet; }
//     @media #{$smallBreakpoint} { background: yellow; }
//     @media #{$xSmallBreakpoint} { background: orange; }
// }
$xxlargeBreakpoint: "(max-width: #{upper-bound($xxlarge-range)})";
$xlargeBreakpoint:  "(max-width: #{upper-bound($xlarge-range)})";
$largeBreakpoint:   "(max-width: #{upper-bound($large-range)})";
$mediumBreakpoint:  "(max-width: #{upper-bound($medium-range)})";
$smallBreakpoint:   "(max-width: #{upper-bound($small-range)})";
$xSmallBreakpoint:  "(max-width: #{$xsmall-range})";

// Ranges
// div {
//     background: black;
//     @media #{$xxlargeRange} { background: red; }
//     @media #{$xlargeRange} { background: green; }
//     @media #{$largeRange} { background: blue; }
//     @media #{$mediumRange} { background: violet; }
//     @media #{$smallRange} { background: yellow; }
// }
$xxlargeRange: "(min-width: #{lower-bound($xxlarge-range)}) and (max-width: #{upper-bound($xxlarge-range)})";
$xxlargeRange: "(min-width: #{lower-bound($xxlarge-range)}) and (max-width: #{upper-bound($xxlarge-range)})";
$xlargeRange:  "(min-width: #{lower-bound($xlarge-range)}) and (max-width: #{upper-bound($xlarge-range)})";
$largeRange:   "(min-width: #{lower-bound($large-range)}) and (max-width: #{upper-bound($large-range)})";
$mediumRange:  "(min-width: #{lower-bound($medium-range)}) and (max-width: #{upper-bound($medium-range)})";
$smallRange:   "(min-width: #{lower-bound($small-range)}) and (max-width: #{upper-bound($small-range)})";
